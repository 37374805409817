"use client";

import { FC, PropsWithChildren, useEffect } from "react";
import { useRouter } from "next/navigation";

type Props = {
  heading: string;
  subheading: string;
} & PropsWithChildren;

export const AuthLayout: FC<Props> = ({ heading, subheading, children }) => {
  const router = useRouter();

  useEffect(() => {
    const session = localStorage.getItem("SESSION");
    if (session === "on") {
      router.replace("/");
    }
  }, [router]);

  return (
    <div className="p-5 xl:p-8">
      <h1 className="mb-1 text-3xl font-semibold tracking-tight text-slate-900">
        {heading}
      </h1>
      <p className="text-base text-slate-700 xl:text-lg">{subheading}</p>

      {children}
    </div>
  );
};
